
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
  skip
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 6.5.0
 * Query Engine version: 173f8d54f8d52e692c7e27e72a88314ec7aeff60
 */
Prisma.prismaVersion = {
  client: "6.5.0",
  engine: "173f8d54f8d52e692c7e27e72a88314ec7aeff60"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}



/**
 * Enums
 */

exports.Prisma.AccountScalarFieldEnum = {
  id: 'id',
  userId: 'userId',
  type: 'type',
  provider: 'provider',
  providerAccountId: 'providerAccountId',
  refresh_token: 'refresh_token',
  access_token: 'access_token',
  expires_at: 'expires_at',
  token_type: 'token_type',
  scope: 'scope',
  id_token: 'id_token',
  session_state: 'session_state'
};

exports.Prisma.OfficeScalarFieldEnum = {
  id: 'id',
  name: 'name',
  roleMapping: 'roleMapping',
  industry: 'industry',
  addressL1: 'addressL1',
  addressL2: 'addressL2',
  addressCity: 'addressCity',
  addressState: 'addressState',
  addressPostal: 'addressPostal',
  addressCountry: 'addressCountry',
  phone: 'phone',
  fax: 'fax',
  email: 'email',
  website: 'website',
  logo: 'logo',
  color: 'color',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  dateFormat: 'dateFormat',
  ownerId: 'ownerId'
};

exports.Prisma.UserOfficeInvitationScalarFieldEnum = {
  id: 'id',
  dateAccepted: 'dateAccepted',
  inviterId: 'inviterId',
  invitedId: 'invitedId',
  officeId: 'officeId',
  role: 'role'
};

exports.Prisma.UserOfficeTeamScalarFieldEnum = {
  id: 'id',
  teamName: 'teamName',
  description: 'description',
  userId: 'userId',
  officeId: 'officeId',
  teamMembersIds: 'teamMembersIds',
  templateIds: 'templateIds',
  formIds: 'formIds'
};

exports.Prisma.SubscriptionScalarFieldEnum = {
  id: 'id',
  coveredUserIds: 'coveredUserIds',
  perMonthTotal: 'perMonthTotal',
  billingPeriod: 'billingPeriod',
  billingDay: 'billingDay',
  billingYear: 'billingYear',
  bAFirstName: 'bAFirstName',
  bALastName: 'bALastName',
  bAAddress: 'bAAddress',
  bACity: 'bACity',
  bAState: 'bAState',
  bAZip: 'bAZip',
  bACountry: 'bACountry',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  prefix: 'prefix',
  firstName: 'firstName',
  lastName: 'lastName',
  pronouns: 'pronouns',
  role: 'role',
  roleInherited: 'roleInherited',
  grantedRole: 'grantedRole',
  plan: 'plan',
  trialEnds: 'trialEnds',
  stripeCustomerId: 'stripeCustomerId',
  subscriptionIds: 'subscriptionIds',
  subscriptionId: 'subscriptionId',
  subscriptionCoveredUserIds: 'subscriptionCoveredUserIds',
  email: 'email',
  workEmail: 'workEmail',
  phone: 'phone',
  workPhone: 'workPhone',
  workPhoneExt: 'workPhoneExt',
  emailVerified: 'emailVerified',
  image: 'image',
  hpId: 'hpId',
  pinId: 'pinId',
  pinLength: 'pinLength',
  twoFAEnabled: 'twoFAEnabled',
  lastLogin: 'lastLogin',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  signature: 'signature',
  favoriteIds: 'favoriteIds',
  hasInvitedToPlatformIds: 'hasInvitedToPlatformIds',
  invitedToPlatformByIds: 'invitedToPlatformByIds',
  referredUserIds: 'referredUserIds',
  referringUserIds: 'referringUserIds',
  officeIds: 'officeIds',
  officeId: 'officeId',
  sentMessagesId: 'sentMessagesId',
  viewableMessagesId: 'viewableMessagesId',
  patientDocumentsId: 'patientDocumentsId',
  invitedToEditFormIds: 'invitedToEditFormIds',
  invitedToViewFormIds: 'invitedToViewFormIds',
  visitedInvitedToFormIds: 'visitedInvitedToFormIds',
  approvedFormIds: 'approvedFormIds',
  formId: 'formId',
  ownerOfTeamIds: 'ownerOfTeamIds',
  teamMemberIds: 'teamMemberIds',
  aiTokenUsage: 'aiTokenUsage',
  IPs: 'IPs',
  RLCount: 'RLCount',
  RLExpiry: 'RLExpiry'
};

exports.Prisma.RateLimitIPScalarFieldEnum = {
  id: 'id',
  ip: 'ip',
  count: 'count',
  expiry: 'expiry'
};

exports.Prisma.PatientDocumentsScalarFieldEnum = {
  id: 'id',
  patientId: 'patientId',
  updatedAt: 'updatedAt',
  participantsId: 'participantsId',
  documentTransactionsIds: 'documentTransactionsIds'
};

exports.Prisma.DocumentTransactionScalarFieldEnum = {
  id: 'id',
  data: 'data',
  updatedAt: 'updatedAt',
  patientDocumentsId: 'patientDocumentsId',
  viewableUserIds: 'viewableUserIds',
  senderId: 'senderId'
};

exports.Prisma.SessionScalarFieldEnum = {
  id: 'id',
  sessionToken: 'sessionToken',
  expires: 'expires',
  userId: 'userId'
};

exports.Prisma.TemplateEntryFormScalarFieldEnum = {
  id: 'id',
  title: 'title',
  tabs: 'tabs',
  officeId: 'officeId',
  forceAccordionsOpen: 'forceAccordionsOpen',
  hideAccordionStyle: 'hideAccordionStyle',
  lastAccordionButtonText: 'lastAccordionButtonText',
  runAIFillOnFirstLoadTabs: 'runAIFillOnFirstLoadTabs',
  userId: 'userId',
  isGlobal: 'isGlobal',
  isActive: 'isActive'
};

exports.Prisma.FormScalarFieldEnum = {
  id: 'id',
  title: 'title',
  regarding: 'regarding',
  note: 'note',
  open: 'open',
  fields: 'fields',
  reports: 'reports',
  extraReports: 'extraReports',
  lastEdited: 'lastEdited',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  ownerId: 'ownerId',
  editorIds: 'editorIds',
  currentEditors: 'currentEditors',
  viewerIds: 'viewerIds',
  hasViewed: 'hasViewed',
  hasEdited: 'hasEdited',
  teamIds: 'teamIds',
  officeId: 'officeId',
  templateEntryFormId: 'templateEntryFormId',
  templateReportsFormId: 'templateReportsFormId',
  isArchived: 'isArchived',
  isValidated: 'isValidated',
  isApproved: 'isApproved',
  isDownloaded: 'isDownloaded',
  isSent: 'isSent',
  versionSuffixs: 'versionSuffixs',
  reportsForApproval: 'reportsForApproval',
  hiddenFields: 'hiddenFields',
  userEditedFields: 'userEditedFields',
  approvedByUserId: 'approvedByUserId',
  clientId: 'clientId',
  userId: 'userId',
  aiFillData: 'aiFillData',
  aiFillDataAddressed: 'aiFillDataAddressed'
};

exports.Prisma.SecureSendScalarFieldEnum = {
  id: 'id',
  formId: 'formId',
  reports: 'reports',
  userId: 'userId',
  expires: 'expires',
  token: 'token',
  status: 'status'
};

exports.Prisma.ClientScalarFieldEnum = {
  id: 'id',
  prefix: 'prefix',
  firstName: 'firstName',
  lastName: 'lastName',
  dateOfBirth: 'dateOfBirth',
  pronouns: 'pronouns',
  email: 'email',
  phone: 'phone',
  officeId: 'officeId',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt'
};

exports.Prisma.PDFDownloadScalarFieldEnum = {
  id: 'id',
  formId: 'formId',
  userId: 'userId',
  fileNames: 'fileNames'
};

exports.Prisma.VerificationTokenScalarFieldEnum = {
  id: 'id',
  identifier: 'identifier',
  jwt: 'jwt',
  expires: 'expires',
  code: 'code',
  RLCount: 'RLCount',
  RLimit: 'RLimit'
};

exports.Prisma.OTPScalarFieldEnum = {
  id: 'id',
  secret: 'secret',
  email: 'email'
};

exports.Prisma.HashedPasswordsScalarFieldEnum = {
  id: 'id',
  hashedPassword: 'hashedPassword'
};

exports.Prisma.UserPinScalarFieldEnum = {
  id: 'id',
  pin: 'pin'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};
exports.Industry = exports.$Enums.Industry = {
  Dental: 'Dental',
  Logistics: 'Logistics',
  Unknown: 'Unknown'
};

exports.UserRole = exports.$Enums.UserRole = {
  SpecialistAdmin: 'SpecialistAdmin',
  Admin: 'Admin',
  Specialist: 'Specialist',
  Hygienist: 'Hygienist',
  Staff: 'Staff',
  Patient: 'Patient'
};

exports.Prisma.ModelName = {
  Account: 'Account',
  Office: 'Office',
  UserOfficeInvitation: 'UserOfficeInvitation',
  UserOfficeTeam: 'UserOfficeTeam',
  Subscription: 'Subscription',
  User: 'User',
  RateLimitIP: 'RateLimitIP',
  PatientDocuments: 'PatientDocuments',
  DocumentTransaction: 'DocumentTransaction',
  Session: 'Session',
  TemplateEntryForm: 'TemplateEntryForm',
  Form: 'Form',
  SecureSend: 'SecureSend',
  Client: 'Client',
  PDFDownload: 'PDFDownload',
  VerificationToken: 'VerificationToken',
  OTP: 'OTP',
  HashedPasswords: 'HashedPasswords',
  UserPin: 'UserPin'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
