import { isProdEnv, isTestEnv } from "@/packages/common/src/utils/environments";
import * as Prisma from "@/root/.generated/prisma/client/app-core";
import { InputJsonValue, JsonValue } from "@prisma/client/runtime/library";

declare global {
  var prisma: Prisma.PrismaClient;
}

const client = global.prisma || new Prisma.PrismaClient();
if (isProdEnv() || isTestEnv()) {
  global.prisma = client;
} else {
  //local dev
  if (!global.prisma) {
    global.prisma = new Prisma.PrismaClient({ log: ["query", "info", "warn", "error"] });
  }
  prisma = global.prisma;
}

// Re-export PrismaClient and the entire Prisma namespace
export * from "@/root/.generated/prisma/client/app-core"; //  re-export everything from the @prisma/client module. It will export all named exports from @prisma/client directly from module.
//export * as Prisma ... would be better for slightly more structured and potentially more robust code in larger projects, but it will not export all named exports from @prisma/client directly from module.

export type PrismaClient = Prisma.PrismaClient; // Export the PrismaClient

export default prisma; //exports the prisma client instance, declared as const prisma = global.prisma || new Prisma.PrismaClient();

/* * * * * * * * * * * * * * *
  Helper Functions
* * * * * * * * * * * * * * */
export function excludeKeyFromUser<User extends Record<string, any>, Key extends keyof User>(user: User, ...keys: Key[]): Omit<User, Key> {
  const filteredEntries = Object.entries(user).filter(([key]) => !keys.includes(key as Key));

  return Object.fromEntries(filteredEntries) as Omit<User, Key>;
}

// 1) Overload for array input
export function prismaJsonToObject(prismaJSON: JsonValue[] | any[]): any[];
// 2) Overload for single value (object/string/etc.)
export function prismaJsonToObject(prismaJSON?: JsonValue | any): Record<string, any>;
// 3) Implementation signature - the actual function body
export function prismaJsonToObject(prismaJSON?: JsonValue | JsonValue[] | any): Record<string, any> | any[] {
  if (!prismaJSON) {
    // If undefined or null, return an empty object
    return {};
  }

  // If it's already an array, just return it as `any[]`.
  if (Array.isArray(prismaJSON)) {
    return (prismaJSON ?? []) as any[];
  }

  // If it's a string, parse it as JSON
  if (typeof prismaJSON === "string") {
    return JSON.parse(prismaJSON) as Record<string, any>;
  }

  // Otherwise, assume it's an object
  // (If you might have booleans/numbers, you can handle that separately)
  return (prismaJSON ?? {}) as Record<string, any>;
}

export function objectToInputJsonValue(object: Record<string, any>): InputJsonValue {
  return object as InputJsonValue;
}
